import React, { useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
import TailwindHeader from '../components/header/TailwindHeader';
import { Footer, HelmetComponent } from '../components';

import { DEFAULT_ROUTE_PATHS } from '../routes/RouteDeclarations';
import ProductOverview from '../components/product-overview/ProductOverview';
import TailwindBanner from '../components/banner/TailwindBanner';
import { siteMetadata } from '../utils/Constants';

const ProductOverviewContainer = () => {
    // const history = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
        // ReactGA.set({ page: history.pathname });
        // ReactGA.send({ hitType: 'pageview', page: history.pathname, title: '${siteMetadata.title} -- Templates' });
    }, []);

    return (
      <>
      <HelmetComponent
        title={`${siteMetadata.title} -- Product Overview`}
        description="Discover our collection of digital business templates to help you grow your online business. Choose from a variety of templates to suit your needs."
        URL={`${siteMetadata.siteUrl}${DEFAULT_ROUTE_PATHS.TEMPLATDETAILS}`}
      />
      <TailwindBanner />
      <TailwindHeader />
      <ProductOverview />
      <Footer />
      </>
    );
};

export default ProductOverviewContainer;
