import React from 'react';
import { Helmet } from 'react-helmet';
import { siteMetadata } from '../../utils/Constants';

const HelmetComponent = ({ title, description, URL }) => {
  return (
    <Helmet>
      <title>{title}</title>
      {/* General meta tags */}
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta name="robots" content="index, follow" />
      <meta name="author" content={`${siteMetadata.title}`} />
      <meta name="keywords" content="business template, liability waiver, digital downloads, digital templates, instant download template" />
      <meta name="theme-color" content="#2E8B57" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta httpEquiv="Content-Language" content="en" />
      {/* links */}
      <link rel="canonical" href={URL} />
      {/* Open Graph protocol */}
      <meta property="og:url" content={URL} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content="https://digitalbiztemplates.com/favicon.png" />
      <meta property="og:site_name" content="DigitalBizTemplates" />
      <meta property="og:type" content="article" />
      {/* Twitter protocol */}
      <meta property="twitter:url" content={URL} />
      <meta property="twitter:description" content={description} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:image" content="https://digitalbiztemplates.com/favicon.png" />
      <meta name="twitter:site" content="@digitalbiztemplates" />
      {/* JSON-LD (JavaScript Object Notation for Linked Data) */}
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org",
          "@type": "WebSite",
          "url": ${URL},
          "name": ${title},
          "description": ${description},
          "sameAs": [
            "https://www.facebook.com/porpoisedesigns",
            "https://twitter.com/digitalbiztemplates",
            "https://www.instagram.com/porpoisedesigns.co"
          ]
        }
        `}
      </script>
    </Helmet>
  );
};

export default HelmetComponent;
