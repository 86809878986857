import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Post } from '../blog-post/BlogPostFuncs';
import {
 ReactGAActions, ReactGACategory, ReactGALabels,
 siteMetadata,
} from '../../utils/Constants';

export default function TailwindBlog({ posts }: { posts: Post[] }) {

  const history = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
      ReactGA.set({ page: history.pathname });
      ReactGA.send({ hitType: 'pageview', page: history.pathname, title: `${siteMetadata.title} Blog - Tips and Stories for Business Owners Big and Small` });
  }, []);

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            From the blog
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            {`Welcome to ${siteMetadata.title} blog, where we share insights, tips, and
            stories for business owners big and small. We cover a wide range of topics and
            inspire you to take action.`}
          </p>
          <Link
            to="/tags"
            className="font-bold"
          >Or View Tags
          </Link>
        </div>
        <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.length > 0
            && posts.map((post: Post) => {
              return (
                <article
                  key={post.slug}
                  className="flex max-w-xl flex-col items-start justify-between"
                >
                  <div className="flex items-center gap-x-4 text-xs">
                    <time
                      dateTime={post.metadata.createdOn}
                      className="text-gray-500"
                    >
                      {post.metadata.createdOn}
                    </time>
                    {post.metadata.tags.map((tag) => {
                      return (
                        <span className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100">
                          {tag}
                        </span>
                      );
                    })}
                  </div>
                  <div className="group relative">
                  <img
                      src={post.metadata.headerImageName}
                      alt=""
                      className="rounded max-h-45"
                  />
                    <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                      <Link
                      to={`/blog/${post.slug}`}
                      onClick={() => {
                        const dimensions = { dimension1: `/blog/${post.slug}` };
                        ReactGA.event({
                            category: ReactGACategory.Button,
                            action  : ReactGAActions.Click,
                            label   : ReactGALabels.BlogPostLink,
                            ...dimensions,
                        });
                      }}
                      >
                        <span className="absolute inset-0" />
                        {post.metadata.title}
                      </Link>
                    </h3>
                    <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">
                      {post.metadata.description}
                    </p>
                  </div>
                  <div className="relative mt-8 flex items-center gap-x-4">
                    <img
                      src={post.metadata.author.profileImage}
                      alt=""
                      className="h-10 w-10 rounded-full bg-gray-50"
                    />
                    <div className="text-sm leading-6">
                      <p className="font-semibold text-gray-900">
                        <a href={post.metadata.author.profileImage}>
                          <span className="absolute inset-0" />
                          {post.metadata.author.name}
                        </a>
                      </p>
                      <p className="text-gray-600">
                        {post.metadata.author.bio}
                      </p>
                    </div>
                  </div>
                </article>
              );
            })}
        </div>
      </div>
    </div>
  );
}
