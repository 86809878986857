import React from 'react';
import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';
// import TailwindFlyoutMenu from '../flyout/TailwindFlyoutMenu';
import {
 ReactGAActions, ReactGACategory, ReactGALabels,
 RouteToLabelMap,
 siteMetadata,
} from '../../utils/Constants';
import { globalUIContext, UIContext } from '../../context/UIContext';
import TailwindFlyoutMenu from '../flyout/TailwindFlyoutMenu';

export const navigation = [
  { name: 'Home', href: '/' },
  { name: 'Reviews', href: '/reviews' },
  { name: 'Shop Templates', href: '/templates' },
  { name: 'Blog', href: '/blog' },
  { name: 'About', href: '/about' },
  { name: 'FAQ', href: '/faq' },
];

export default function TailwindHeader() {
  const uiContext: UIContext = React.useContext(globalUIContext);
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);

  return (
    <header className="relative inset-x-0 top-0 z-50">
      <nav
        className="flex items-center justify-between p-2 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <Link
          to="/"
          className="-m-1.5 p-1.5"
          onClick={() => {
            ReactGA.event({
                category: ReactGACategory.Button,
                action  : ReactGAActions.Click,
                label   : ReactGALabels.HomePageLink,
            });
            uiContext.actions.setTag(undefined);
          }}
          >
            <span className="sr-only">{siteMetadata.author}</span>
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => {
              return setMobileMenuOpen(true);
            }}
          >
            <span className="sr-only">Open Main Menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12 text-lg">
          {navigation.map((item, index) => {
            if (item.name === 'Tools') {
                return (
                <TailwindFlyoutMenu key={`${index.toFixed()}`} name="Tools" />
                );
            }
                return (
                  <Link
                    key={`${index.toFixed()}`}
                    to={item.href}
                    className="text-large font-semibold leading-6 text-gray-900"
                    onClick={() => {
                      ReactGA.event({
                          category: ReactGACategory.Button,
                          action  : ReactGAActions.Click,
                          label   : RouteToLabelMap[item.href],
                      });
                      uiContext.actions.setTag(undefined);
                  }}
                  >
                    {item.name}
                  </Link>
                );

          })}
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end text-lg">
          <Link
            to="/"
            className="font-semibold leading-6 text-gray-900 text-lg"
            onClick={() => {
              ReactGA.event({
                category: ReactGACategory.Button,
                action  : ReactGAActions.Click,
                label   : ReactGALabels.HomePageLink,
              });
              uiContext.actions.setTag(undefined);
            }}
          />
        </div>
        {/* Make hidden on widths smaller than lg */}
        <div className="hidden lg:flex lg:justify-end">
          {/* <Link
          to="/state-of-remote-work-2024"
          className="-m-1.5 p-1.5 hover:no-underline"
          onClick={() => {
            ReactGA.event({
                category: ReactGACategory.Button,
                action  : ReactGAActions.Click,
                label   : ReactGALabels.StateOfRemoteWork2024Link,
            });
            uiContext.actions.setTag(undefined);
          }}
          >
            <div
            className="h-8 w-auto rounded-md text-white font-semibold p-1 px-2 no-underline bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-80"
            >Download Our FREE 2024 State of Remote Work Resource!
            </div>
          </Link> */}
        </div>
      </nav>
      <Dialog
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-50" />
        <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center text-3xl justify-between">
            <Link
            to="/"
            className="-m-1.5 p-1.5"
            onClick={() => {
              ReactGA.event({
                  category: ReactGACategory.Button,
                  action  : ReactGAActions.Click,
                  label   : ReactGALabels.HomePageLink,
              });
              uiContext.actions.setTag(undefined);
          }}
            >
              <span className="sr-only">{siteMetadata.author}</span>
              <img
                className="h-8 w-auto p-1.5"
                src={siteMetadata.home.image}
                alt=""
              />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => {
                return setMobileMenuOpen(false);
              }}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6 " aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="flex flex-col space-y-2 py-6">
                {navigation.map((item, index: any) => {
                  if (item.name === 'Tools') {
                    return (
                        <TailwindFlyoutMenu key={`${index.toFixed()}`} name="Tools" />
                    );
                }
                    return (
                      <Link
                        key={`${index.toFixed()}`}
                        to={item.href}
                        className="text-lg font-semibold leading-6 text-gray-900"
                        onClick={() => {
                          ReactGA.event({
                              category: ReactGACategory.Button,
                              action  : ReactGAActions.Click,
                              label   : RouteToLabelMap[item.href],
                          });
                        }}
                      >
                        {item.name}
                      </Link>
                    );

                })}
              </div>
              <div className="py-6" />
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
}
