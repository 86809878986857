import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import TailwindHeader from '../components/header/TailwindHeader';
import TailwindFeatureSection from '../components/feature/TailwindFeatureSection';
import { Footer, HelmetComponent } from '../components';

import { DEFAULT_ROUTE_PATHS } from '../routes/RouteDeclarations';
import TailwindBanner from '../components/banner/TailwindBanner';
import { siteMetadata } from '../utils/Constants';
import TailwindNewsLetterSignUp from '../components/sign-up/TailwindNewsletterSignUp';

export default function About() {
  const history = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.set({ page: history.pathname });
    ReactGA.send({ hitType: 'pageview', page: history.pathname, title: `About Us - ${siteMetadata.title}` });
}, []);

  return (
    <>
    <HelmetComponent
      title={`About Us - ${siteMetadata.title}`}
      description={`Learn more about ${siteMetadata.title}, our mission, and how we support businesses make transformative digital changes through our templates`}
      URL={`${siteMetadata.siteUrl}${DEFAULT_ROUTE_PATHS.ABOUT}`}
    />
     <TailwindBanner />
      <TailwindHeader />
      <TailwindFeatureSection />
      <TailwindNewsLetterSignUp />
      <Footer />
    </>
  );
}
