import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import TailwindHeader from '../components/header/TailwindHeader';
import TailwindHome from '../components/central/TailwindHome';
// import TailwindFeatureSection from '../components/feature/TailwindFeatureSection';
// import TailwindCTA from '../components/cta/TailwindCTA';
import TailwindNewsLetterSignUp from '../components/sign-up/TailwindNewsletterSignUp';
import { Footer, HelmetComponent } from '../components';

// import { Post } from '../components/blog-post/BlogPostFuncs';
// import TailwindBanner from '../components/banner/TailwindBanner';
import {
  EtsyProducts,
 ReactGAActions, ReactGACategory, ReactGALabels, ReactGALocationDimension,
 siteMetadata,
} from '../utils/Constants';
import TailwindBanner from '../components/banner/TailwindBanner';
import TailwindFeatureSection from '../components/feature/TailwindFeatureSection';
import TailwindCTA from '../components/cta/TailwindCTA';
import TestimonialsSection from '../components/testimonials/TestimonialsSection';
import TailwindFAQ from '../components/faq/TailwindFAQ';

export default function Home() {
  const history = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
      ReactGA.set({ page: history.pathname });
      ReactGA.send({ hitType: 'pageview', page: history.pathname, title: `${siteMetadata.title} - Embrace a Life of Freedom and Adventure` });
      const dimensions = { dimension1: ReactGALocationDimension.Footer };
      ReactGA.event({
        category: ReactGACategory.Button,
        action  : ReactGAActions.Click,
        label   : ReactGALabels.BlogPageLink,
       ...dimensions,
    });
  }, []);

  return (
    <>
      <HelmetComponent
      title={`${siteMetadata.title} - Instant Download Customizable Business Templates`}
      description=""
      URL={siteMetadata.siteUrl}
      />
      <TailwindBanner />
      <TailwindHeader />
      <TailwindHome />
      <TailwindFeatureSection />
      <TestimonialsSection />
      <TailwindNewsLetterSignUp />
      <TailwindCTA products={EtsyProducts} />
      <TailwindFAQ />
      <Footer />
    </>
  );
}
