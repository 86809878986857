import React, { useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
import TailwindHeader from '../components/header/TailwindHeader';
import { Footer, HelmetComponent } from '../components';
import ProductList from '../components/product-list/ProductList';

import { DEFAULT_ROUTE_PATHS } from '../routes/RouteDeclarations';
import TailwindBanner from '../components/banner/TailwindBanner';
import { siteMetadata } from '../utils/Constants';
import TailwindNewsLetterSignUp from '../components/sign-up/TailwindNewsletterSignUp';

const ProductListContainer = () => {
    // const history = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
        // ReactGA.set({ page: history.pathname });
        // ReactGA.send({ hitType: 'pageview', page: history.pathname, title: '${siteMetadata.title} -- Templates' });
    }, []);

    return (
      <>
      <HelmetComponent
        title={`${siteMetadata.title} -- Templates`}
        description="Discover our collection of digital business templates to help you grow your online business. Choose from a variety of templates to suit your needs."
        URL={`${siteMetadata.siteUrl}${DEFAULT_ROUTE_PATHS.TEMPLATES}`}
      />
       <TailwindBanner />
      <TailwindHeader />
      <ProductList />
      <TailwindNewsLetterSignUp />
      <Footer />
      </>
    );
};

export default ProductListContainer;
