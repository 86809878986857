import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import TailwindHeader from '../components/header/TailwindHeader';
import { Footer, HelmetComponent } from '../components';
import TailwindFAQ from '../components/faq/TailwindFAQ';

import { DEFAULT_ROUTE_PATHS } from '../routes/RouteDeclarations';
import TailwindBanner from '../components/banner/TailwindBanner';
import { siteMetadata } from '../utils/Constants';
import TailwindNewsLetterSignUp from '../components/sign-up/TailwindNewsletterSignUp';

export default function FAQ() {
  const history = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
      ReactGA.set({ page: history.pathname });
      ReactGA.send({ hitType: 'pageview', page: history.pathname, title: `Frequently Asked Questions - ${siteMetadata.title}` });
  }, []);

  return (
    <>
    <HelmetComponent
      title={`Frequently Asked Questions - ${siteMetadata.title}`}
      description="Have questions about our templates? Find answers to the most commonly asked questions on our FAQ page."
      URL={`${siteMetadata.siteUrl}${DEFAULT_ROUTE_PATHS.FAQ}`}
    />
     <TailwindBanner />
      <TailwindHeader />
      <TailwindFAQ />
      <TailwindNewsLetterSignUp />
      <Footer />
    </>
  );
}
