import React from 'react';
import { StarIcon } from '@heroicons/react/20/solid';
import { useLocation } from 'react-router-dom';
import { EtsyProduct, EtsyProducts } from '../../utils/Constants';

// 'use client';


const reviews = { href: 'https://www.etsy.com/shop/porpoisedesigns', average: 5, totalCount: 37 };

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function ProductOverview() {
  const history = useLocation();

  const product = EtsyProducts.find((p: EtsyProduct) => {
    return p.path === history.pathname;
  });

  if (product === undefined) {
  return <div>No product found with this URL.</div>;
  }

  return (
    <div className="bg-white">
      <div className="pt-20">
        {/* <nav aria-label="Breadcrumb">
          <ol
            className="mx-auto flex max-w-2xl items-center space-x-2 px-4 sm:px-6 lg:max-w-7xl lg:px-8"
          >
            {product.breadcrumbs.map((breadcrumb) => {
 return (
              <li key={breadcrumb.id}>
                <div className="flex items-center">
                  <a href={breadcrumb.href} className="mr-2 text-sm font-medium text-gray-900">
                    {breadcrumb.name}
                  </a>
                  <svg
                    fill="currentColor"
                    width={16}
                    height={20}
                    viewBox="0 0 16 20"
                    aria-hidden="true"
                    className="h-5 w-4 text-gray-300"
                  >
                    <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                  </svg>
                </div>
              </li>
            );
})}
            <li className="text-sm">
              <a href={product.href} aria-current="page" className="font-medium text-gray-500 hover:text-gray-600">
                {product.name}
              </a>
            </li>
          </ol>
        </nav> */}

        {/* Image gallery */}
        <div className="mx-auto mt-6 sm:px-6 grid grid-cols-3 lg:max-w-7xl lg:grid-cols-3 lg:gap-x-8 lg:px-8">
          <div className="aspect-h-4 aspect-w-3 rounded-lg lg:block">
          <a
            className=""
            href={product.href}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt={product.images[0].alt}
              src={product.images[0].src}
              className="h-full w-full object-cover object-center"
            />
          </a>
          </div>
          <div className="lg:grid lg:grid-cols-1 lg:gap-y-8">

            <div className="aspect-h-2 aspect-w-3 rounded-lg">
            <a
            className=""
            href={product.href}
            target="_blank"
            rel="noopener noreferrer"
            >
              <img
                alt={product.images[1].alt}
                src={product.images[1].src}
                className="h-full w-full object-cover object-center"
              />
            </a>
            </div>
            <div className="aspect-h-2 aspect-w-3 rounded-lg">
            <a
            className=""
            href={product.href}
            target="_blank"
            rel="noopener noreferrer"
            >
              <img
                alt={product.images[2].alt}
                src={product.images[2].src}
                className="h-full w-full object-cover object-center"
              />
            </a>
            </div>
          </div>
          <div className="aspect-h-5 aspect-w-4 lg:aspect-h-4 lg:aspect-w-3 sm:rounded-lg">
          <a
            className=""
            href={product.href}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt={product.images[3].alt}
              src={product.images[3].src}
              className="h-full w-full object-cover object-center"
            />
          </a>
          </div>
        </div>

        {/* Product info */}
        <div className="mx-auto px-4 pb-16 pt-10 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pt-16">
          <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{product.name}</h1>
            <div className="pt-10">
                <p className="text-large text-gray-900">{product.description}</p>
            </div>
          </div>
          {/* Options */}
          <div className="mt-4 lg:row-span-3 lg:mt-0">
            <h2 className="sr-only">Product information</h2>
            <p className="text-3xl tracking-tight text-gray-900">{product.price}</p>

            {/* Reviews */}
            <div className="mt-6">
              <h3 className="sr-only">Reviews</h3>
              <div className="flex items-center">
                <div className="flex items-center">
                  {[0, 1, 2, 3, 4].map((rating) => {
 return (
                    <StarIcon
                      key={rating}
                      aria-hidden="true"
                      className={classNames(
                        reviews.average > rating ? 'text-gray-900' : 'text-gray-200',
                        'h-5 w-5 flex-shrink-0',
                      )}
                    />
                  );
})}
                </div>
                <p className="sr-only">{reviews.average} out of 5 stars</p>
                <a href={reviews.href} className="ml-3 text-sm font-medium text-indigo-600 hover:text-indigo-500">
                  {reviews.totalCount} reviews
                </a>
              </div>
              <button
              type="button"
              onClick={() => {
                window.open(product.href, '_blank', 'noopener noreferrer');
              }}
              className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >Buy on Etsy
              </button>
            </div>
          </div>
        </div>

          <div className="lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pb-16 lg:pl-28 lg:pr-8 lg:max-w-6xl">
            {/* Description and details */}
            <div className="mt-10">
              <h3 className="text-xl font-medium text-gray-900">Highlights</h3>

              <div className="mt-4">
                <ul className="list-disc space-y-2 pl-4 text-medium">
                  {product.highlights.map((highlight) => {
 return (
                    <li key={highlight} className="text-gray-400">
                      <span className="text-gray-600">{highlight}</span>
                    </li>
                  );
})}
                </ul>
              </div>
            </div>

            <div className="mt-10">
              <h2 className="text-xl font-medium text-gray-900">Details</h2>

              <div className="mt-4 space-y-6">
                <p className="text-medium text-gray-600">{product.details}</p>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
}
