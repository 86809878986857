import React from 'react';
import {
 Footer, HelmetComponent, Link, Tag,
} from '../components/index';
import { getAllTags, kebabCase } from '../utils/Utils';
import TailwindHeader from '../components/header/TailwindHeader';
import TailwindBanner from '../components/banner/TailwindBanner';

const Tags = () => {
  const tags = getAllTags();
  const sortedTags = Object.keys(tags).sort((a, b) => {
    return tags[b] - tags[a];
  });
  return (
    <>
      <HelmetComponent
        title="Tags | DigitalBizTemplates"
        description="Tags for all content on DigitalBizTemplates."
        URL="https://digitalbiztemplates.com/tags"
      />
      <TailwindBanner />
      <TailwindHeader />
      <div className="overflow-hidden bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-1">
            <div className="lg:pr-8 lg:pt-4">
              <div className="lg:max-w-3xlg">
                <h2 className="text-base font-semibold leading-7 text-indigo-600">
                  Navigate to the right content with ease
                </h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Tags
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Explore our various tags to find the perfect content for your
                  next trip.
                </p>
                <div className="flex flex-col items-start justify-start divide-y divide-gray-200 dark:divide-gray-700 md:justify-center md:items-center md:divide-y-0 md:flex-row md:space-x-6 md:mt-24">
                  {/* <div className="pt-6 pb-8 space-x-2 md:space-y-5">
                    <h1 className="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl sm:leading-10 md:text-6xl md:leading-14 md:border-r-2 md:px-6">
                        Tags
                    </h1>
                </div> */}
                  <div className="flex flex-wrap">
                    {Object.keys(tags).length === 0 && 'No tags found.'}
                    {sortedTags.map((t) => {
                      return (
                        <div key={t} className="mt-2 mb-2 mr-5">
                          <Tag text={t} />
                          <Link
                            href={`/tags/${kebabCase(t)}`}
                            className="-ml-2 text-sm font-semibold text-gray-600 uppercase dark:text-gray-300"
                          >
                            {` (${tags[t]})`}
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Tags;
