import React, { Suspense, useEffect, useRef } from 'react';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';
import {
  ReactGAActions,
  ReactGACategory,
  ReactGALabels,
  siteMetadata,
  UsefulServices,
} from '../../utils/Constants';

const Icon = ({ className, pathData }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      aria-hidden="true"
      className={className}
    >
      <path fillRule="evenodd" d={pathData} clipRule="evenodd" />
    </svg>
  );
};

const LazyLoadVideoComponent: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) return;

    const handleIntersection = (
      entries: IntersectionObserverEntry[],
      observer: IntersectionObserver,
    ) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          Array.from(videoElement.children).forEach((source) => {
            if (source.tagName === 'SOURCE') {
              const videoSource = source as HTMLSourceElement;
              if (videoSource.dataset.src) {
                videoSource.src = videoSource.dataset.src;
              }
            }
          });
          videoElement.load();
          observer.unobserve(videoElement);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection);
    observer.observe(videoElement);

    // eslint-disable-next-line consistent-return
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <video
      ref={videoRef}
      autoPlay
      className="absolute top-0 left-0 w-full h-full object-cover overflow-hidden"
      loop
      muted
    >
      <source data-src="/nzl.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

const LazyLoadVideoComponentLazy = React.lazy(() => {
  return Promise.resolve({ default: LazyLoadVideoComponent });
});

export default function TailwindHome() {
  return (
    <div className="bg-white">
      <Suspense fallback={<div>Loading video...</div>}>
        {false && <LazyLoadVideoComponentLazy />}
      </Suspense>
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div className="mx-auto max-w-2xl py-32 sm:py-32 lg:py-32">
          <div className="sm:mb-8 sm:flex sm:justify-center py-3">
            <div
              style={{ backgroundColor: '#F1700D' }}
              className="relative rounded-full px-3 py-1 text-lg leading-6 text-black ring-1 ring-gray-900/10 hover:ring-gray-900/20 font-bold flex justify-center text-center"
            >
              Empowering Women-Led Businesses Around The Globe
              <a
                href={UsefulServices.EtsyShopHome}
                className="font-semibold text-white"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  ReactGA.event({
                    category: ReactGACategory.Button,
                    action  : ReactGAActions.Click,
                    label   : ReactGALabels.AITravelGuideLink,
                  });
                }}
              >
                <span className="absolute inset-0" aria-hidden="true" />
              </a>
            </div>
          </div>
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-black sm:text-6xl">
              {siteMetadata.title}
            </h1>
            <p className="mt-6 text-lg leading-8 text-black font-bold">
              {siteMetadata.description}
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href={UsefulServices.EtsyShopHome}
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  ReactGA.event({
                    category: ReactGACategory.Button,
                    action  : ReactGAActions.Click,
                    label   : ReactGALabels.AITravelGuideLink,
                  });
                }}
              >
                Check Out Our Shop Here!
              </a>
              <Link
                to="/templates"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Browse Templates <span aria-hidden="true">→</span>
              </Link>
            </div>
            <div className="max-w-2xl mx-auto mt-24">
              <div className="mt-12 font-semibold text-sm md:text-sm flex flex-col md:flex-row justify-between md:gap-x-6">
                <div className="flex flex-col items-center justify-center text-gray-600 text-sm mb-4 md:mb-0">
                  <span className="flex gap-x-1">
                    {[...Array(5)].map((_, index) => {
                      return (
                        <Icon
                          key={index.toFixed(2)}
                          className="h-5 w-5 flex-none text-yellow-500"
                          pathData="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401Z"
                        />
                      );
                    })}
                  </span>
                  <span>
                    5 Stars
                    <span className="font-slate-400 text-sm font-normal">
                      (500+ Ratings)
                    </span>
                  </span>
                </div>

                <div className="flex flex-col items-center justify-center text-gray-600 mb-4 md:mb-0">
                  <span className="flex gap-x-1">
                    {[...Array(5)].map((_, index) => {
                      return (
                        <Icon
                          key={index.toFixed(2)}
                          className="h-5 w-5 flex-none text-blue-400"
                          pathData="M11.983 1.907a.75.75 0 0 0-1.292-.657l-8.5 9.5A.75.75 0 0 0 2.75 12h6.572l-1.305 6.093a.75.75 0 0 0 1.292.657l8.5-9.5A.75.75 0 0 0 17.25 8h-6.572l1.305-6.093Z"
                        />
                      );
                    })}
                  </span>
                  <span>10,000+ Templates Made</span>
                </div>

                <div className="flex flex-col items-center justify-center text-gray-600 mb-4 md:mb-0">
                  <span className="flex gap-x-1">
                    {[...Array(5)].map((_, index) => {
                      return (
                        <Icon
                          key={index.toFixed(2)}
                          className="h-5 w-5 flex-none text-pink-500"
                          pathData="M10 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM6 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM1.49 15.326a.78.78 0 0 1-.358-.442 3 3 0 0 1 4.308-3.516 6.484 6.484 0 0 0-1.905 3.959c-.023.222-.014.442.025.654a4.97 4.97 0 0 1-2.07-.655ZM16.44 15.98a4.97 4.97 0 0 0 2.07-.654.78.78 0 0 0 .357-.442 3 3 0 0 0-4.308-3.517 6.484 6.484 0 0 1 1.907 3.96 2.32 2.32 0 0 1-.026.654ZM18 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM5.304 16.19a.844.844 0 0 1-.277-.71 5 5 0 0 1 9.947 0 .843.843 0 0 1-.277.71A6.975 6.975 0 0 1 10 18a6.974 6.974 0 0 1-4.696-1.81Z"
                        />
                      );
                    })}
                  </span>
                  <span>Used by 1,000+ Businesses</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>
    </div>
  );
}
