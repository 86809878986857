import { Language } from './Entities';

export const siteMetadata = {
  title       : 'Digital Biz Templates',
  author      : 'digitalbiztemplates',
  headerTitle : '👋 🙇‍♂️',
  description : 'Instantly Downloadable & Customizable Business Templates Made With ❤️',
  language    : 'en-us',
  theme       : 'system', // system, dark or light
  siteUrl     : 'https://digitalbiztemplates.com', // http://localhost:3000
  siteRepo    : 'https://github.com/timlrx/tailwind-nextjs-starter-blog',
  siteLogo    : '/static/favicon.svg',
  image       : '/static/images/avatar.png',
  imageAlt    : 'Logo',
  socialBanner: '/static/images/twitter-card.png',
  email       : 'mwong17@cmc.edu',
  linkedin    : 'https://www.linkedin.com/in/digitalbiztemplates',
  locale      : 'en-US',
  home        : {
      image: '/logo.png',
  },
  blog: {
      tagline: 'Hi there! Here you will find information about my thoughts around digital nomading and travel.',
  },
};

// AppUrls are links used throughout the app
export enum AppUrls {
    UTM_PARAMS = '?utm_source=website&utm_medium=social',
    WEBSITE_URL = 'https://digitalbiztemplates.com',
    MATT_WONG_BASE_URL = '',
    FACEBOOK_PAGE_URL = 'https://www.facebook.com/profile.php?id=100093074860687',
    INSTAGRAM_URL = 'https://www.instagram.com/porpoisedesigns.co',
    TIKTOK_URL = 'https://www.tiktok.com/@porpoisedesigns',
    PINTEREST_URL = 'https://www.pinterest.com/tallmarmaladeporpoise',
    LINKEDIN_URL = 'https://www.linkedin.com/company/porpoisedesigns',
    HASHICORP= 'https://www.hashicorp.com/',
    TERRAFORM_PROVIDERS = 'https://registry.terraform.io/browse/providers',
    TERRAFORM_DOWNLOAD = 'https://learn.hashicorp.com/terraform/getting-started/install.html',
    AWS_CLI_DOWNLOAD = 'https://docs.aws.amazon.com/cli/latest/userguide/install-cliv2.html',
    TERRAFORM_BACKEND= 'https://www.terraform.io/docs/backends/index.html',
    REACT_SYNTAX_HIGHLIGHTER = 'https://www.npmjs.com/package/react-syntax-highlighter',
    REACT_MARKDOWN = 'https://www.npmjs.com/package/react-markdown',
    ORYX_CONFIGURER = 'https://configure.zsa.io/',
    SIDE_HUSTLE_STACK = 'https://sidehustlestack.co/',
    MAGIC_EDEN_MARKETPLACE = 'https://magiceden.io/',
    CORAL_CUBE_MARKETPLACE = 'https://coralcube.io/',
    OPENSEA_MARKETPLACE = 'https://opensea.io/',
    FRACTAL_MARKETPLACE = 'https://www.fractal.is/',
    SOLANART_MARKETPLACE = 'https://solanart.io/',
    SOLSEA_MARKETPLACE = 'https://solsea.io/',
    InstallZSH = 'https://github.com/ohmyzsh/ohmyzsh/wiki/Installing-ZSH',
    WhatIsOhMyZsh = 'https://ohmyz.sh/',
    WhatIsCurl = 'https://curl.se/',
    WhatIsWGet = 'https://www.gnu.org/software/wget/',
    ZSHPluginsList = 'https://github.com/ohmyzsh/ohmyzsh/wiki/Plugins-Overview',
    ZSHThemesList = 'https://github.com/ohmyzsh/ohmyzsh/wiki/Themes',
}

export interface EtsyProduct {
  name: string;
  price: string;
  path: string;
  href: string;
  images: {
    src: string;
    alt: string;
  }[];
  description: string;
  highlights: string[];
  details: string;
}

export const EtsyProducts: EtsyProduct[] = [
  {
    name  : 'Liability Waiver Template',
    price : '$15.00',
    path  : '/templates/liability-waiver',
    href  : 'https://porpoisedesigns.etsy.com/listing/1481254042',
    images: [
      { src: '/images/products/liability-waiver/2.jpg', alt: '' },
      { src: '/images/products/liability-waiver/1.jpg', alt: '' },
      { src: '/images/products/liability-waiver/3.jpg', alt: '' },
      { src: '/images/products/liability-waiver/4.jpg', alt: '' },
    ],
    description: 'This Liability Waiver Template is perfect for any business that needs to protect themselves from liability. It is easy to use and can be customized to fit your needs. This template is perfect for gyms, fitness centers, yoga studios, and more. It is available for instant download and can be used right away.',
    highlights : ['Instantly Downloadable', 'Fully customizable with font, text, and color changes', 'Available in multiple formats, including PDF, Document, and Pages', 'Perfect for gyms, small businesses, fitness centers, yoga studios, and more'],
    details    : 'This Liability Waiver Template is perfect for any business that needs to protect themselves from liability. It is easy to use and can be customized to fit your needs. This template is perfect for gyms, fitness centers, yoga studios, and more. It is available for instant download and can be used right away.',
  },
  {
    name  : 'NDA Template',
    price : '$15.00',
    path  : '/templates/nda-template',
    href  : 'https://porpoisedesigns.etsy.com/listing/1523461952',
    images: [
      { src: '/images/products/nda/1.png', alt: '' },
      { src: '/images/products/nda/2.png', alt: '' },
      { src: '/images/products/nda/3.png', alt: '' },
      { src: '/images/products/nda/4.png', alt: '' },
    ],
    description: 'This NDA Template is perfect for any business that needs to protect their confidential information. It is easy to use and can be customized to fit your needs. This template is perfect for small businesses, startups, and more. It is available for instant download and can be used right away.',
    highlights : ['Instantly Downloadable', 'Fully customizable with font, text, and color changes', 'Available in multiple formats, including PDF, Document, and Pages', 'Perfect for gyms, small businesses, fitness centers, yoga studios, and more'],
    details    : 'This NDA Template is perfect for any business that needs to protect their confidential information. It is easy to use and can be customized to fit your needs. This template is perfect for small businesses, startups, and more. It is available for instant download and can be used right away.',
  },
  {
    name  : 'Daycare Starter Kit',
    price : '$15.00',
    path  : '/templates/daycare-starter-kit',
    href  : 'https://porpoisedesigns.etsy.com/listing/1768487858',
    images: [
      { src: '/images/products/daycare-starter-kit/1.jpg', alt: '' },
      { src: '/images/products/daycare-starter-kit/2.jpg', alt: '' },
      { src: '/images/products/daycare-starter-kit/3.jpg', alt: '' },
      { src: '/images/products/daycare-starter-kit/4.jpg', alt: '' },
    ],
    description: 'Introducing the Daycare Starter Kit Document Template—your all-in-one solution to launching and managing a successful daycare with ease.',
    highlights : ['Instantly Downloadable', 'Fully customizable with font, text, and color changes', 'Available in multiple formats, including PDF, Document, and Pages', 'Perfect for gyms, small businesses, fitness centers, yoga studios, and more'],
    details    : 'This comprehensive, customizable template includes essential forms, checklists, and guides to help you stay organized and compliant from day one. Whether you\'re just starting or looking to streamline your existing daycare operations, this kit ensures you have everything you need, from enrollment forms to safety protocols, all in one beautifully designed package. Save time, reduce stress, and focus on what matters most—caring for the children.',
  },
  {
    name  : 'Daycare Schedule Template',
    price : '$15.00',
    path  : '/templates/daycare-schedule-template',
    href  : 'https://porpoisedesigns.etsy.com/listing/1502370297',
    images: [
      { src: '/images/products/daycare-schedule/1.jpg', alt: '' },
      { src: '/images/products/daycare-schedule/2.jpg', alt: '' },
      { src: '/images/products/daycare-schedule/3.jpg', alt: '' },
      { src: '/images/products/daycare-schedule/4.jpg', alt: '' },
    ],
    description: 'This Daycare Schedule Template is perfect for any business that needs to protect their confidential information. It is easy to use and can be customized to fit your needs. This template is perfect for small businesses, startups, and more. It is available for instant download and can be used right away.',
    highlights : ['Instantly Downloadable', 'Fully customizable with font, text, and color changes', 'Available in multiple formats, including PDF, Document, and Pages', 'Perfect for gyms, small businesses, fitness centers, yoga studios, and more'],
    details    : 'This Daycare Schedule Template is perfect for any business that needs to protect their confidential information. It is easy to use and can be customized to fit your needs. This template is perfect for small businesses, startups, and more. It is available for instant download and can be used right away.',
  },
  {
    name  : 'Daycare Emergency Plan Kit',
    price : '$15.00',
    path  : '/templates/daycare-emergency-plan',
    href  : 'https://porpoisedesigns.etsy.com/listing/1505202739',
    images: [
      { src: '/images/products/daycare-emergency-plan/1.jpg', alt: '' },
      { src: '/images/products/daycare-emergency-plan/2.jpg', alt: '' },
      { src: '/images/products/daycare-emergency-plan/3.jpg', alt: '' },
      { src: '/images/products/daycare-emergency-plan/4.jpg', alt: '' },
    ],
    description: 'Are you starting your own childcare or daycare business? Look no further. Get all the daycare forms and paperwork you need with this daycare starter kit.',
    highlights : ['Instantly Downloadable', 'Fully customizable with font, text, and color changes', 'Available in multiple formats, including PDF, Document, and Pages', 'Perfect for gyms, small businesses, fitness centers, yoga studios, and more'],
    details    : 'This lovely Daycare Starter Kit Template features modern calligraphy with a minimalist layout and is perfect to add to your branding. After you place your order, you will receive PDF download with access to your self-editable template where you will be able to customize all your own details!',
  },
  {
    name  : 'Non-Compete Form Template',
    price : '$10.00',
    path  : '/templates/non-compete-form-template',
    href  : 'https://porpoisedesigns.etsy.com/listing/1559769240',
    images: [
      { src: '/images/products/non-compete-form/1.png', alt: '' },
      { src: '/images/products/non-compete-form/2.png', alt: '' },
      { src: '/images/products/non-compete-form/3.png', alt: '' },
      { src: '/images/products/non-compete-form/4.png', alt: '' },
    ],
    description: 'This lovely Non-Compete Agreement Template features modern calligraphy with a minimalist layout and is perfect to add to your branding.',
    highlights : ['Instantly Downloadable', 'Fully customizable with font, text, and color changes', 'Available in multiple formats, including PDF, Document, and Pages', 'Perfect for gyms, small businesses, fitness centers, yoga studios, and more'],
    details    : 'After you place your order, you will receive PDF download with access to your self-editable template where you will be able to customize all your own details!',
  },
  {
    name  : 'Eviction Notice Template',
    price : '$10.00',
    path  : '/templates/eviction-notice-template',
    href  : 'https://porpoisedesigns.etsy.com/listing/1563558974',
    images: [
      { src: '/images/products/eviction-notice/1.png', alt: '' },
      { src: '/images/products/eviction-notice/2.png', alt: '' },
      { src: '/images/products/eviction-notice/3.png', alt: '' },
      { src: '/images/products/eviction-notice/4.png', alt: '' },
    ],
    description: 'This lovely Eviction Notice Template features modern calligraphy with a minimalist layout and is perfect to add to your branding.',
    highlights : ['Instantly Downloadable', 'Fully customizable with font, text, and color changes', 'Available in multiple formats, including PDF, Document, and Pages', 'Perfect for gyms, small businesses, fitness centers, yoga studios, and more'],
    details    : 'After you place your order, you will receive PDF download with access to your self-editable template where you will be able to customize all your own details!',
  },
  {
    name  : 'PMU Client Intake Template',
    price : '$10.00',
    path  : '/templates/pmu-client-intake-template',
    href  : 'https://porpoisedesigns.etsy.com/listing/1499252973',
    images: [
      { src: '/images/products/pmu/1.jpg', alt: '' },
      { src: '/images/products/pmu/2.jpg', alt: '' },
      { src: '/images/products/pmu/3.jpg', alt: '' },
      { src: '/images/products/pmu/4.jpg', alt: '' },
    ],
    description: 'This Daycare Schedule Template is perfect for any business that needs to protect their confidential information. It is easy to use and can be customized to fit your needs. This template is perfect for small businesses, startups, and more. It is available for instant download and can be used right away.',
    highlights : ['Instantly Downloadable', 'Fully customizable with font, text, and color changes', 'Available in multiple formats, including PDF, Document, and Pages', 'Perfect for gyms, small businesses, fitness centers, yoga studios, and more'],
    details    : 'This Daycare Schedule Template is perfect for any business that needs to protect their confidential information. It is easy to use and can be customized to fit your needs. This template is perfect for small businesses, startups, and more. It is available for instant download and can be used right away.',
  },
  {
    name  : 'Sublet Rental Agreement Template',
    price : '$15.00',
    path  : '/templates/sublet-rental-agreement',
    href  : 'https://www.etsy.com/listing/1561521016/sublet-rental-agreement-template',
    images: [
      { src: '/images/products/sublet-rental-agreement/1.png', alt: '' },
      { src: '/images/products/sublet-rental-agreement/2.png', alt: '' },
      { src: '/images/products/sublet-rental-agreement/3.png', alt: '' },
      { src: '/images/products/sublet-rental-agreement/4.png', alt: '' },
    ],
    description: "This Sublet Rental Agreement Template is ideal for subleasing arrangements. It's easy to use, customizable, and instantly downloadable.",
    highlights : [
      'Instantly Downloadable',
      'Fully customizable with font, text, and color changes',
      'Available in multiple formats, including PDF, Document, and Pages',
      'Perfect for subletting apartments and other rental properties',
    ],
    details: 'This template helps to outline the terms and conditions of a sublease arrangement clearly and professionally.',
  },
  {
    name  : 'Contractor Invoice Template',
    price : '$15.00',
    path  : '/templates/contractor-invoice-template',
    href  : 'https://www.etsy.com/listing/1561539256/contractor-invoice-template-contractor',
    images: [
      { src: '/images/products/contractor-invoice-template/1.jpg', alt: '' },
      { src: '/images/products/contractor-invoice-template/2.jpg', alt: '' },
      { src: '/images/products/contractor-invoice-template/3.jpg', alt: '' },
      { src: '/images/products/contractor-invoice-template/4.jpg', alt: '' },
    ],
    description: "This Contractor Invoice Template is perfect for freelance contractors. It's customizable, easy to use, and available for instant download.",
    highlights : [
      'Instantly Downloadable',
      'Fully customizable with font, text, and color changes',
      'Available in multiple formats, including PDF, Document, and Pages',
      'Perfect for freelance contractors and small businesses',
    ],
    details: 'The Contractor Invoice Template offers a professional and organized way to bill clients for services rendered.',
  },
  {
    name  : 'Minimalist Invoice Template',
    price : '$20.00',
    path  : '/templates/minimalist-invoice-template',
    href  : 'https://www.etsy.com/listing/1561539256/minimalist-invoice-template',
    images: [
      { src: '/images/products/minimalist-invoice-template/1.jpg', alt: '' },
      { src: '/images/products/minimalist-invoice-template/2.jpg', alt: '' },
      { src: '/images/products/minimalist-invoice-template/3.jpg', alt: '' },
      { src: '/images/products/minimalist-invoice-template/4.jpg', alt: '' },
    ],
    description: "This Minimalist Invoice Template is perfect for modern businesses. It's customizable, easy to use, and available for instant download.",
    highlights : [
      'Instantly Downloadable',
      'Fully customizable with font, text, and color changes',
      'Available in multiple formats, including PDF, Document, and Pages',
      'Perfect for small businesses, freelancers, and creatives',
    ],
    details: 'The Minimalist Invoice Template offers a clean, professional way to bill clients for services rendered.',
  },
  {
    name  : 'Complete Wedding Planning Checklist',
    price : '$15.00',
    path  : '/templates/wedding-planning-checklist',
    href  : 'https://www.etsy.com/listing/5016423941/wedding-planning-checklist',
    images: [
      { src: '/images/products/wedding-planning-checklist/1.jpg', alt: '' },
      { src: '/images/products/wedding-planning-checklist/2.jpg', alt: '' },
      { src: '/images/products/wedding-planning-checklist/3.jpg', alt: '' },
      { src: '/images/products/wedding-planning-checklist/4.jpg', alt: '' },
    ],
    description: "This Complete Wedding Planning Checklist is perfect for organizing all aspects of your wedding day. It's customizable, easy to use, and available for instant download.",
    highlights : [
      'Instantly Downloadable',
      'Fully customizable with font, text, and color changes',
      'Available in multiple formats, including PDF, Document, and Pages',
      'Perfect for brides, wedding planners, and event coordinators',
    ],
    details: 'The Complete Wedding Planning Checklist ensures every detail of your big day is covered, making planning stress-free and efficient.',
  },
  {
    name  : 'Photography Shot List Template',
    price : '$19.19',
    path  : '/templates/photography-shot-list',
    href  : 'https://www.etsy.com/listing/5016423941/photography-shot-list-template',
    images: [
      { src: '/images/products/photography-shot-list/1.jpg', alt: '' },
      { src: '/images/products/photography-shot-list/2.jpg', alt: '' },
      { src: '/images/products/photography-shot-list/3.jpg', alt: '' },
      { src: '/images/products/photography-shot-list/4.jpg', alt: '' },
    ],
    description: "This Photography Shot List Template is perfect for wedding photographers to ensure all key moments are captured. It's customizable, easy to use, and available for instant download.",
    highlights : [
      'Instantly Downloadable',
      'Fully customizable with font, text, and color changes',
      'Available in multiple formats, including PDF, Document, and Pages',
      'Perfect for professional photographers and event planners',
    ],
    details: 'The Photography Shot List Template helps photographers stay organized and capture all the important moments on the wedding day.',
  },
  {
    name  : 'Business Forms Bundle Small Business Starter Kit',
    price : '$25.00',
    path  : '/templates/business-forms-bundle',
    href  : 'https://www.etsy.com/listing/1549453996/business-forms-bundle-small-business',
    images: [
      { src: '/images/products/business-forms-bundle/1.png', alt: '' },
      { src: '/images/products/business-forms-bundle/2.png', alt: '' },
      { src: '/images/products/business-forms-bundle/3.png', alt: '' },
      { src: '/images/products/business-forms-bundle/4.png', alt: '' },
    ],
    description: 'This Business Forms Bundle is perfect for startups and small businesses looking for essential legal forms. It is instantly downloadable and fully customizable.',
    highlights : [
      'Instantly Downloadable',
      'Fully customizable with font, text, and color changes',
      'Available in multiple formats, including PDF, Document, and Pages',
      'Perfect for small businesses, startups, and more',
    ],
    details: 'This Business Forms Bundle includes everything you need to get your small business started. Legal forms, business contracts, and more.',
  },
];

export interface Review {
  comment: string;
  buyer: string;
  date: string;
}

export const EtsyReviews: Review[] = [
  {
    comment: 'Great product perfect for my business!',
    buyer  : 'Braya',
    date   : 'Aug 25th, 2024',
  },
  {
    comment: 'Beautiful 💓 loved the template!',
    buyer  : 'Lisa',
    date   : 'July 2nd, 2024',
  },
  {
    comment: 'Great template for creating my own emergency plan.',
    buyer  : 'Eric',
    date   : 'Jun 17th, 2024',
  },
  {
    comment: 'Love this template!! It\'s professional and modern and looks great with my branding colors and logo.',
    buyer  : 'Danielle',
    date   : 'May 9th, 2024',
  },
  {
    comment: 'The ease of this template for a business partnership agreement was extremely helpful, and gave us a great guide to what we were needing. Thank you, we will look for other templates for business and other purposes should the need arise here.',
    buyer  : 'jschlick03',
    date   : 'Nov 28th, 2023',
  },
  {
    comment: 'excellent will order again from this seller',
    buyer  : 'Annette',
    date   : 'Sep 3th, 2023',
  },
  {
    comment: 'Great service, very quick and easy to use.',
    buyer  : 'Anna O Accessories',
    date   : 'Aug 5th, 2023',
  },
  {
    comment: 'Easily editable and clear. Good quality!',
    buyer  : 'Kay',
    date   : 'July 31st, 2023',
  },
  {
    comment: 'Fast and easy to use!',
    buyer  : 'gilmoreisthename1',
    date   : 'Jun 12th, 2023',
  },
  {
    comment: 'This is the cutest liability waiver and perfect for my small business! I like the artsy design and professional feel.',
    buyer  : 'Clara',
    date   : 'Jun 8th, 2023',
  },
  {
    comment: 'I love this template. It is easily adaptable, beautiful, and thorough. I cut and pasted in content for my school and edited it easily. Thank you! You saved me countless hours!',
    buyer  : 'April',
    date   : 'Dec 8th, 2023',
  },
];

// Amazon Affiliate Links
export enum AmazonAffiliateLinks {
    // gadgets
    CherryMxBrownSwitches = 'https://amzn.to/3GExBeY',
    LaptopStand = 'https://amzn.to/3GEUv62',
    YetiMicrophone = 'https://amzn.to/3BSMNCN',
    NexigoWebcam = 'https://amzn.to/3UZtNcv',
    KinesisEdge = 'https://amzn.to/3XnOoIZ',
    LogitechErgo = 'https://amzn.to/3XnwHcB',
    RazorHuntsman = 'https://amzn.to/3VfZ7nD',
    DuckyOne = 'https://amzn.to/3ADdb22',
    // books
    CrackingTheCodingInterview = 'https://amzn.to/3XoIAz1',
    ThePragmaticProgrammer = 'https://amzn.to/3GItNcu',
    CleanCode = 'https://amzn.to/3U0dMBC',
    ZeroToOne = 'https://amzn.to/3i4Dsjk',
    TheHiddenLanguageOfComputerHardware = 'https://amzn.to/3B0MyV9',
    // Keycaps
    HyperX = 'https://amzn.to/3hTRwfp',
    DSA ='https://amzn.to/3XupqrI',
    RazerDoubleShot = 'https://amzn.to/3GDHXeU',
    PBT = 'https://amzn.to/3ACy1yK',
    XDA = 'https://amzn.to/3TZuNMq',
}
// Service Links
export enum UsefulServices {
    AlgoExpert = 'https://www.algoexpert.io/',
    GrokkingSystemDesign = 'https://github.com/Jeevan-kumar-Raj/Grokking-System-Design',
    LevelsFYI = 'https://www.levels.fyi/',
    TeamBlind = 'https://www.teamblind.com/',
    Notion = 'https://www.notion.so/',
    AITravelGuide = 'https://chatgpt.com/g/g-uJJvZK3Wo-traveler-hotel-guide',
    EtsyShopHome = 'https://porpoisedesigns.etsy.com'
}

export enum Gadgets {
  ZSAErgodoxMoonlander = 'https://www.zsa.io/moonlander',
}

export enum ReactGACategory {
  Video = 'Video',
  Button = 'Button',
  Form = 'Form',
  Download = 'Download',
}

export enum ReactGAActions {
  Play = 'Play',
  Submit = 'Submit',
  Click = 'Click',
  Download = 'Download',
}

export enum ReactGALabels {
 HomePageLink = 'HomePageLink',
 BlogPageLink = 'BlogPageLink',
 AboutPageLink = 'AboutPageLink',
 ReviewPageLink = 'ReviewPageLink',
 BlogPostLink = 'BlogPostLink',
 FAQPageLink = 'FAQPageLink',
 TripPlannerLink = 'TripPlannerLink',
 InteractiveMapLink = 'InteractiveMapLink',
 JobBoardLink = 'JobBoardLink',
 StateOfRemoteWork2024Link = 'StateOfRemoteWork2024Link',
 AITravelGuideLink = 'AITravelGuideLink',
}

export enum ReactGALocationDimension {
  Footer = 'Footer',
  Header = 'Header',
  BlogPost = 'BlogPost',
  JobBoard = 'JobBoard',
  BlogPostList = 'BlogPostList',
  BlogPostContent = 'BlogPostContent',
  BlogPostAuthor = 'BlogPostAuthor',
  BlogPostDate = 'BlogPostDate',
  BlogPostTag = 'BlogPostTag',
}

export const RouteToLabelMap = {
  '/'                     : ReactGALabels.HomePageLink,
  '/about'                : ReactGALabels.AboutPageLink,
  '/faq'                  : ReactGALabels.FAQPageLink,
  '/blog'                 : ReactGALabels.BlogPageLink,
  '/blog/:slug'           : ReactGALabels.BlogPostLink,
  '/tools/trip-planner'   : ReactGALabels.TripPlannerLink,
  '/tools/interactive-map': ReactGALabels.InteractiveMapLink,
  '/tools/job-board'      : ReactGALabels.JobBoardLink,
};

export const BlogTagHighlights = [
    'keyboards',
    'interviews',
    'hiring',
    'side-hustles',
    'crypto',
];

export const LessonTagHighlights = [
    'programming',
];

// ETC
export const MAIL_TO_EMAIL: string = 'mailto:mwong17@cmc.edu';
export const ENGLISH_LANG: string = 'english';
export const JAPANESE_LANG: string = 'japanese';

export const LANGUAGE_SET: Array<Language> = [
    {
        name     : 'language.name.english',
        shortName: 'language.short_name.english',
        code     : 'en',
    },
    {
        name     : 'language.name.japanese',
        shortName: 'language.short_name.japanese',
        code     : 'ja',
    },
];

export const getLanguageByCode = (supportedLanguages: Language[], code: string | undefined | null): Language | undefined => {
    if (code) {
        return supportedLanguages.find((language: Language) => {
            return language.code === code;
        });
    }

    return undefined;
};

export const DEFAULT_LANGUAGE = ((): Language => {
    // First, try to find the browser's default language and see if it is one of the languages that we support.
    const browserLanguages: string[] = [];

    // Go though all the languages that we support and find one that has a language code that matches the browser's languages
    const browserLanguageCode: string | undefined = browserLanguages.find(
        (code: string): boolean => {
            const [languageCode] = code.includes('-') ? code.split('-') : code.split('_');
            return getLanguageByCode(LANGUAGE_SET, languageCode) !== undefined;
        },
    );

    // The default language will be either the browser language, or English, or the FIRST language in the list which ever match first
    const result: Language = getLanguageByCode(LANGUAGE_SET, browserLanguageCode)
      || getLanguageByCode(LANGUAGE_SET, 'en')
      || LANGUAGE_SET[0];

    return result;
})();

export const postDateTemplate: any = {
    weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
};

export interface StoreFeatured {
    name: string,
    href: string,
    imageSrc: string,
    imageAlt: string,
    path: string,
}

export interface StoreItemBreadcrumb {
    id: number,
    name: string,
    href: string
}

export interface StoreItemImage {
    src: string,
    alt: string
}

export interface StoreItem {
    name: string,
    href: string,
    path: string,
    tags: string[],
    price: string,
    breadcrumbs: StoreItemBreadcrumb[],
    images: StoreItemImage[],
    description: string,
    highlights: string[],
    details: string,
}
export interface StoreSection {
    id: string,
    name: string,
    items: StoreItem[],
    breadcrumbs: StoreItemBreadcrumb[],
}

export interface StoreCategory {
    id: string,
    name: string,
    featured: StoreFeatured[],
    sections: StoreSection[],
}

export interface StorePage {
    name: string,
    href: string
}
export interface Store {
    categories: StoreCategory[],
    pages: StorePage[]
}
