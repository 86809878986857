import * as React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import Home from '../containers/Home';
import FourZeroFour from '../containers/404';
import { globalUIContext, UIContext } from '../context/UIContext';
import About from '../containers/About';
import ProductListContainer from '../containers/ProductList';
import ProductOverviewContainer from '../containers/ProductOverview';
import FAQ from '../containers/FAQ';
import BlogContainer from '../containers/Blog';
import { getAllPosts, Post } from '../components/blog-post/BlogPostFuncs';
import BlogPage from '../containers/BlogPost';
import Tags from '../containers/Tags';
import Reviews from '../containers/Reviews';

export const DEFAULT_ROUTE_PATHS: { [name: string]: string } = {
  // Informational
  HOME           : '/',
  ABOUT          : '/about',
  FAQ            : '/faq',
  REVIEWS        : '/reviews',
  // Product
  TEMPLATES      : '/templates',
  TEMPLATEDETAILS: '/templates/:slug',
  // Blog-Related
  BLOG           : '/blog',
  BLOGPOST       : '/blog/:slug',
  TAGS           : '/tags',
  TAG            : '/tags/:slug',
  // Comms
  CONTACT        : '/contact',
};

export const RouteDeclarations: React.FC = () => {
  const [posts, setPosts] = React.useState<Post[]>([]);
  const history = useLocation();
  const uiContext: UIContext = React.useContext(globalUIContext);

  useEffect(() => {
    const postResponse = getAllPosts(uiContext.state.tag);
    setPosts(postResponse);
  }, [uiContext.state.tag]);

  return (
    <Routes>
      {/* Informtional */}
      <Route path={DEFAULT_ROUTE_PATHS.HOME} Component={Home} />
      <Route path={DEFAULT_ROUTE_PATHS.ABOUT} Component={About} />
      <Route path={DEFAULT_ROUTE_PATHS.FAQ} Component={FAQ} />
      <Route path={DEFAULT_ROUTE_PATHS.REVIEWS} Component={Reviews} />

      <Route path={DEFAULT_ROUTE_PATHS.TAGS} Component={Tags} />
      <Route
        path={DEFAULT_ROUTE_PATHS.TAG}
        Component={() => {
          const tagValRaw: string = history.pathname.split('/').pop() || '';
          const tagVal: string = tagValRaw.replace(/-/g, ' ');
          uiContext.actions.setTag(tagVal);
          return BlogContainer(posts);
        }}
      />
      {/* Blogs */}
      <Route
        path={DEFAULT_ROUTE_PATHS.BLOG}
        Component={() => {
          return BlogContainer(posts);
        }}
      />
      {posts.map((post, index) => {
        return (
          <Route
            key={`${index.toFixed()}`}
            path={`/blog/${post.slug}`}
            Component={() => {
              return BlogPage(post);
            }}
          />
        );
      })}
      {/* Product */}
      <Route
        path={DEFAULT_ROUTE_PATHS.TEMPLATES}
        Component={ProductListContainer}
      />
      <Route
        path={DEFAULT_ROUTE_PATHS.TEMPLATEDETAILS}
        Component={ProductOverviewContainer}
      />
      {/* Not Found */}
      <Route path="*" Component={FourZeroFour} />
    </Routes>
  );
};
