import React, { useEffect } from 'react';
import './App.css';
import './dist/output.css';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { UIContextProvider } from './context/UIContext';
import { RouteDeclarations } from './routes/RouteDeclarations';


const useSuppressResizeObserverWarning = () => {
  useEffect(() => {
    // Save the original console.warn function
    const originalWarn = console.error;

    console.error = function (message, ...args) {
      if (typeof message === 'string' && message.includes('ResizeObserver loop completed with undelivered notifications')) {
        // Suppress the specific warning
        return;
      }
      // Call the original console.warn function for other warnings
      originalWarn.apply(console, [message, ...args]);
    };

    // Cleanup function to restore the original console.warn
    return () => {
    };
  }, []);
};


function App() {
  useSuppressResizeObserverWarning();

  if (!process.env.REACT_APP_GA_TRACKING_ID) {
    throw new Error('Failed to render application. Missing REACT_APP_GA_TRACKING_ID.');
  }

  const trackingId = process.env.REACT_APP_GA_TRACKING_ID;

  ReactGA.initialize(trackingId);

  return (
    <BrowserRouter>
    <UIContextProvider>
        <RouteDeclarations />
    </UIContextProvider>
    </BrowserRouter>
  );
}

export default App;
