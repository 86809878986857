import React from 'react';
import { Link } from 'react-router-dom';
import { siteMetadata, UsefulServices } from '../../utils/Constants';

export default function TailwindBanner() {
  return (
    <div className="max-sm:hidden relative inline-flex overflow-hidden bg-gray-50 px-6 py-2 sm:px-3.5 sm:before:flex-2
    w-full"
    >
      <div className="flex-start max-sm:hidden z-10">
      <Link style={{ zIndex: 1000 }} to="/">
      <img
      style={{ zIndex: 1000 }}
      className="h-12 w-auto max-sm:hidden"
      src={siteMetadata.home.image}
      alt=""
      />
      </Link>
      </div>
      <div className="ml-4 inline-flex items-center gap-x-4 w-full justify-center">
        <p className="text-sm text-gray-900 max-sm:hidden">
          <strong className="max-sm:hidden font-semibold">SHOPWIDE SALE</strong>
          <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current max-sm:hidden" aria-hidden="true">
            <circle cx={1} cy={1} r={1} />
          </svg>
          Use Discount Code "SUMMER50" for up to a 50% discount on all products!
        </p>
        <a
          href={UsefulServices.EtsyShopHome}
          className="flex-none rounded-full bg-gray-900 px-3.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
          style={{ zIndex: 1000 }}
          target="_blank"
          rel="noopener noreferrer"
        >
           <span aria-hidden="true">Shop Now &rarr;</span>
        </a>
      </div>
    </div>
  );
}
