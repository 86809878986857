import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { siteMetadata } from '../../utils/Constants';

const QuestionMarkHTML = (): React.ReactElement => {
  return (
    <div className="relative pr-2">
      <dt className="potext-base font-semibold leading-7 text-gray-900">
        <div className="left-0 top-0 flex h-5 w-5 items-center justify-center rounded-lg bg-indigo-600">
          <QuestionMarkCircleIcon
            className="h-5 w-5 text-white"
            aria-hidden="true"
          />
        </div>
      </dt>
    </div>
  );
};

export default function TailwindFAQ() {
  return (
    <div className="overflow-hidden bg-white sm:py-5">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="lg:pr-8 lg:pt-4">
          <div className="lg:max-w-xlg">
            <section className="bg-white dark:bg-gray-900">
              <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                <h2 className="mb-8 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  FAQ (Frequently asked questions)
                </h2>
                <div className="grid pt-8 text-left border-t border-gray-200 md:gap-16 dark:border-gray-700 md:grid-cols-2">
                  <div>
                    <div className="mb-10">
                      <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                        {QuestionMarkHTML()}
                        1. How do I download a digital product on Etsy?
                      </h3>
                      <p className="text-gray-500 dark:text-gray-400">
                      After completing your purchase, you'll receive an email with a download link. Alternatively, you can access your purchased items by going to your Etsy account, navigating to "Purchases and Reviews," and finding the product you wish to download.
                      </p>
                    </div>
                    <div className="mb-10">
                      <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                        {QuestionMarkHTML()}
                        2. How can I use the downloaded PDFs to customize the product?
                      </h3>
                      <p className="text-gray-500 dark:text-gray-400">
                      Download the PDF: Begin by downloading the PDF file from Etsy onto your computer or device. Make sure to save it in a location where you can easily access it.

Click the Canva link inside of the PDF and that will open up a tab in your browser.

If you don't have a Canva account, you can create one for free. Once you've created an account, you will be able to use the template and edit it directly.

Once you've finished editing the template, click on the "Download" button in Canva. Choose the desired file format, such as PDF, and Canva will generate a download link for your customized design. Click on the link to save the Canva template to your computer.
                      </p>
                    </div>
                    <div className="mb-10">
                      <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                        {QuestionMarkHTML()}
                        3. Can I customize a Canva template on my mobile device?
                      </h3>
                      <p className="text-gray-500 dark:text-gray-400">
                      Yes, Canva offers a mobile app that allows you to access and customize Canva templates on your smartphone or tablet. Simply download the Canva app from your device's app store, log in to your Canva account, and access the template to begin customization.
                      </p>
                    </div>
                    <div className="mb-10">
                      <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                        {QuestionMarkHTML()}
                        4. What elements of a Canva template can I customize?
                      </h3>
                      <p className="text-gray-500 dark:text-gray-400">
                      Canva templates are highly customizable. You can edit text, change fonts, adjust colors, modify images, add or remove elements, and rearrange the layout to create a design that aligns with your preferences or brand identity.
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="mb-10">
                      <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                        {QuestionMarkHTML()}
                        5. Will I also be provided with the PDF version of the product if I don't want to make changes?
                      </h3>
                      <p className="text-gray-500 dark:text-gray-400">
                      Yes! We will supply the raw product in PDF form for you to print immediately if you do not wish to customize it on Canva.
                      </p>
                    </div>
                    <div className="mb-10">
                      <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                        {QuestionMarkHTML()}
                        6. How long do I have access to the template?
                      </h3>
                      <p className="text-gray-500 dark:text-gray-400">
                        Our templates are a one-time purchase for life, so you will always have the ability to go back in and customize after purchase.
                      </p>
                    </div>
                    <div className="mb-10">
                      <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                        {QuestionMarkHTML()}
                        {`7. How customizable are ${siteMetadata.title}?`}
                      </h3>
                      <p className="text-gray-500 dark:text-gray-400">
                        Our templates are highly customizable. You can edit text, change fonts, adjust colors, modify images, add or remove elements, and rearrange the layout to create a design that aligns with your preferences or brand identity.
                      </p>
                    </div>
                    <div className="mb-10">
                      <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                        {QuestionMarkHTML()}
                        10. How do I access my purchased templates?
                      </h3>
                      <p className="text-gray-500 dark:text-gray-400">
                      After completing your purchase, you'll receive an email with a download link. Alternatively, you can access your purchased templates by going to your Etsy account, navigating to "Purchases and Reviews," and finding the template you wish to download.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
