import React from 'react';
import { EtsyReviews, Review, siteMetadata } from '../../utils/Constants';

const GenerateReviews = (review: Review): React.ReactElement => {
        return (
          <li>
          <ul className="flex flex-col gap-y-6 sm:gap-y-8">
          <li key={review.buyer}>
            <section className="px-2 py-8 sm:py-4">
          <figure className="relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10">
            <p className="sr-only">5 out of 5 stars</p>
            <div className="flex gap-x-1 text-indigo-600">
              {/* SVG icons for stars */}
              {[...Array(5)].map((_, index) => {
                return (
                  <svg
                    key={index.toFixed(2)}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    data-slot="icon"
                    className="h-5 w-5 flex-none"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401Z"
                      clipRule="evenodd"
                    />
                  </svg>
                );
              })}
            </div>
            <blockquote className="mt-10 text-md font-semibold leading-6 tracking-tight text-gray-900 sm:text-lg sm:leading-6">
              <p>
                {review.comment}
              </p>
            </blockquote>
            <figcaption className="mt-10 flex items-center gap-x-6">
              <div className="text-sm leading-6">
                <div className="mt-0.5 text-gray-600">
                  <strong>{review.buyer}</strong>
                  <br />
                  {review.date}
                </div>
              </div>
            </figcaption>
          </figure>
            </section>
          </li>
          </ul>
          </li>
  );
};
export default function TestimonialsSection() {

  return (
    <section
      id="testimonials"
      aria-label="What our customers are saying"
      className="bg-gradient-to-t from-indigo-500 via-purple-500 to-pink-500 py-16 sm:py-12 sm:pb-32"
    >
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto md:text-center">
          <p className="text-3xl font-display tracking-tight text-white font-semibold">
            Digital Templates For Everyone
          </p>
          <p className="mt-1 text-lg tracking-tight text-white">
            {`${siteMetadata.title} is helping Mom & Pop Shops, Large Businesses, and even small teams without
            legal or admin teams build better & faster.`}
          </p>
        </div>

        <ul
          className="mx-auto mt-4 grid max-w-2xl grid-cols-1 gap-6 sm:gap-8 lg:mt-8 lg:max-w-none lg:grid-cols-3"
        >
            {EtsyReviews.map((review) => { return GenerateReviews(review); })}
        </ul>

        <div className="mx-auto md:text-center my-16 w-3/4">
          <p className="text-xl tracking-tight text-white font-semibold">
            Ready to get started?
          </p>
        </div>
      </div>
    </section>
  );
}
