import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import TailwindHeader from '../components/header/TailwindHeader';
import { Footer, HelmetComponent } from '../components';
import TailwindBlog from '../components/blog/TailwindBlog';

import { DEFAULT_ROUTE_PATHS } from '../routes/RouteDeclarations';
import { Post } from '../components/blog-post/BlogPostFuncs';
import TailwindBanner from '../components/banner/TailwindBanner';
import { siteMetadata } from '../utils/Constants';
import TailwindNewsLetterSignUp from '../components/sign-up/TailwindNewsletterSignUp';

export default function BlogContainer(posts: Post[]) {

  const history = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.set({ page: history.pathname });
    ReactGA.send({ hitType: 'pageview', page: history.pathname, title: `${siteMetadata.title} Blog - Tips and Stories for Business Owners Big and Small` });
}, []);

  return (
    <>
    <HelmetComponent
      title={`${siteMetadata.title} Blog - Tips and Stories for Business Owners Big and Small"`}
      description={`${siteMetadata.title} Blog - Tips and Stories for Business Owners Big and Small"`}
      URL={`${siteMetadata.siteUrl}${DEFAULT_ROUTE_PATHS.BLOG}`}
    />
      <TailwindBanner />
      <TailwindHeader />
      <TailwindBlog posts={posts} />
      <TailwindNewsLetterSignUp />
      <Footer />
    </>
  );
}
