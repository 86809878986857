/* eslint-disable import/no-relative-packages */
import { slug } from 'github-slugger';

import { getAllPosts, Post } from '../components/blog-post/BlogPostFuncs';
import { siteMetadata } from './Constants';

export const formatDate = (date: string) => {
    const options: any = {
        year : 'numeric',
        month: 'long',
        day  : 'numeric',
    };
    const now = new Date(date).toLocaleDateString(siteMetadata.locale, options);

    return now;
};

export const kebabCase = (str: string) => { return slug(str); };

export const getAllTags = () => {
    const blogData: Post[] = getAllPosts();
    const tagCount: { [key: string]: number } = {};
    // Iterate through each post, putting all found tags into `tags`
    [...blogData].forEach((blog: Post) => {
        if (blog.metadata.tags) {
            blog.metadata.tags.forEach((tag) => {
                const formattedTag = kebabCase(tag);
                if (formattedTag in tagCount) {
                    tagCount[formattedTag] += 1;
                } else {
                    tagCount[formattedTag] = 1;
                }
            });
        }
    });
    return tagCount;
};

const { replace } = '';

// escape
const ca = /[&<>'"]/g;

const esca: { [key: string]: string } = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    "'": '&#39;',
    '"': '&quot;',
};
const pe = (m: string) => { return esca[m]; };

/**
 * Safely escape HTML entities such as `&`, `<`, `>`, `"`, and `'`.
 * @param {string} es the input to safely escape
 * @returns {string} the escaped input, and it **throws** an error if
 *  the input type is unexpected, except for boolean and numbers,
 *  converted as string.
 */
export const escape = (es: string) => { return replace.call(es, ca, pe); };

export const capitalizeFirst = (sentence: string): string => {
    return `${sentence.substring(0, 1).toUpperCase()}${sentence.substring(1)}`;
};

export const capitalizeAll = (sentence: string): string => {
    return sentence.split('-').map((word: string): string => {
        return `${word.substring(0, 1).toUpperCase()}${word.substring(1)}`;
    }).join(' ');
};

export const capitalizeAndRemoveSpace = (sentence: string): string => {
    return sentence.split('-').map((word: string): string => {
        return `${word.substring(0, 1).toUpperCase()}${word.substring(1)}`;
    }).join('');
};
