import React from 'react';
import {
 CurrencyDollarIcon, GlobeAsiaAustraliaIcon, SparklesIcon, FlagIcon,
} from '@heroicons/react/20/solid';

const features = [
  {
    name       : 'Focus on what matters.',
    description: 'Whether you\'re looking to create business forms, NDAs, liability waivers, wedding checklists, or jump start your daycare business with daycare starter kit bundles, our digital templates are meticulously crafted to help you achieve your goals with ease and style. We take pride in our attention to detail, ensuring that every template is visually appealing, well-organized, and user-friendly, saving you valuable time and effort in the design process.',
    icon       : CurrencyDollarIcon,
  },
  {
    name       : 'Fully-customizable for any vertical.',
    description: 'Our templates are thoughtfully curated to cater to various industries, including corporate, creative, fashion, hospitality, and more. With a focus on modern aesthetics, clean lines, and harmonious color palettes, our designs exude professionalism, elegance, and sophistication. Each template is fully customizable and available for instant download, enabling you to effortlessly tailor it to your unique brand identity, incorporating your logo, fonts, and color scheme seamlessly',
    icon       : GlobeAsiaAustraliaIcon,
  },
  {
    name: 'Aesthetically Pleasing',
    description:
      'Our templates are thoughtfully curated to cater to various industries, including corporate, creative, fashion, hospitality, and more. With a focus on modern aesthetics, clean lines, and harmonious color palettes, our designs exude professionalism, elegance, and sophistication. Each template is fully customizable and available for instant download, enabling you to effortlessly tailor it to your unique brand identity, incorporating your logo, fonts, and color scheme seamlessly.',
    icon: FlagIcon,
  },
  {
    name       : 'Start saving big today.',
    description: 'Join the growing community of satisfied customers who have benefited from our digital templates. Take your business or event to new heights with PorpoiseDesigns, where design excellence meets convenience and affordability.',
    icon       : SparklesIcon,
  },
];

export default function TailwindFeatureSection() {
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">
                Unlock Time
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Focus On Building Your Business, Not Process</p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
              Designing and creating a beautiful, user-friendly, and accessible digital experience is no easy task. With our expertise in design, we've created a library of templates that cater to the needs of both small and large businesses as well as special events. Founded on the principle of empowering women-led businesses, our company is dedicated to providing high-quality digital download templates designed to streamline operations, boost productivity, and foster creativity. We believe in the power of women entrepreneurs to shape the future, and our carefully curated templates—from business plans to marketing strategies—are tailored to meet the unique challenges faced by women in business. With a focus on simplicity, modern aesthetics, and practicality, we aim to equip leaders with the tools they need to thrive in the digital age, allowing them to focus on what they do best: leading and innovating.
              Our digital templates are meticulously crafted to help you achieve your goals with ease and style. We take pride in our attention to detail, ensuring that every template is visually appealing, well-organized, and guraranteed to save you valuable time and effort in the design process.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => {
 return (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                );
})}
              </dl>
            </div>
          </div>
          <img
            src="/business.jpg"
            alt="Product screenshot"
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>
  );
}
